.mobile-login {
  background-color: white;
  .ant-form {
    padding: 20px 25px 0px 24px !important;
  }
  .form-label {
    font-size: 16px;
    line-height: 25px;
  }
  .login-btn {
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: 600px !important;
    color: white;
    border-radius: 6px;
    background-color: var(--background-color) !important;
    span {
      color: white !important;
    }
  }
  .modalfooter {
    padding: 0px 10px;
    margin-bottom: 10px;
    .ant-btn {
      padding: 4px 8px !important;
    }
  }
  .svg-button-wrapper {
    .ant-spin.ant-spin-spinning svg {
      color: #fff;
    }
  }
  .login-button-text-mobile {
    font-size: 16px;
    font-weight: 600px !important;
    color: white;
  }
}
